<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Customers</li>

  <li>
    <a routerLink="/customers/create">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add Customer
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="customers"
      (userRowSelect)="onRowSelect($event)">
    </ng2-smart-table>
  </div>
</div>