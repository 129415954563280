import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';

import { RideshareAvailabilityService } from './rideshare-availability.service';
import { FlashService } from '../components/flash/flash.service';
import { RideshareAvailability } from './rideshare-availbility';
import { NewRideshareAvailabilityModalComponent } from './new-modal/new-modal.component';

@Component({
  selector: 'app-view',
  templateUrl: './rideshare-availability.component.html',
  styleUrls: [
    '../../themes/ng2-smart-table.scss',
    './rideshare-availability.component.scss'
  ]
})
export class RidshareAvailabilityComponent implements OnInit {
  modal: BsModalRef;
  availabilities: RideshareAvailability[];
  loading = false;

  tableConfig = {
    columns: {
      id: {
        title: 'ID',
        width: '75px'
      },

      zipcode: {
        title: 'Zipcode'
      },

      availability: {
        title: 'Availability'
      },

      source: {
        title: 'Source of Data (Uber, Lyft, Roundtrip)'
      },

      created_on: {
        title: 'Created On',
        width: '200px',
        compareFunction: (direction, a, b) => {
          return (moment(a).isAfter(b) ? 1 : -1) * direction;
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private flashService: FlashService,
    private rideshareAvailabilityService: RideshareAvailabilityService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ availability }) => this.formatData(availability.data));
  }

  public openNewModal() {
    const initialState = {};
    this.modal = this.modalService.show(
      NewRideshareAvailabilityModalComponent,
      { initialState, class: 'show-modal' }
    );

    const hiddenSubscription = this.modalService.onHidden.subscribe(() => {
      if (this.modal.content.added) {
        this.modal.content.added = false;
        this.loading = true;

        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> Rideshare availabilities have been created and/or updated.',
          timeout: 15000,
        });

        this.handleDuplicateZipcodesFlash()
        this.handleUnknownGradesFlash()
        this.handleInvalidEntriesFlash()
        this.handleInvalidZipcodesFlash()

        this.rideshareAvailabilityService.get().subscribe(({ data }) => {
          this.loading = false;
          this.formatData(data);
        });
      }

      hiddenSubscription.unsubscribe();
    });


    return false;
  }

  private handleDuplicateZipcodesFlash() {
    if (this.modal.content.duplicateZipcodes.length > 0) {
      this.flashService.add({
        type: 'warning',
        message: `<strong>Duplicate Zipcodes Found:</strong> (The first entry found is what was used to update the database)<br />${this.modal.content.duplicateZipcodes.join(', ')}`,
        timeout: 15000,
      });
    }
  }

  private handleUnknownGradesFlash() {
    if (this.modal.content.unknownGrades.length > 0) {
      const message = this.modal.content.unknownGrades
        .map(({ zipcode, grade }) => `Zipcode: ${zipcode}, Grade: ${grade}`)
        .join('<br />')

      this.flashService.add({
        type: 'warning',
        message: `<strong>Unknown Grades Found:</strong> (Please let a Roundtrip Engineer know of the new grades)<br />${message}`,
        timeout: 15000,
      });
    }
  }

  private handleInvalidEntriesFlash() {
    if (this.modal.content.invalidEntriesRowNumber.length > 0) {
      this.flashService.add({
        type: 'warning',
        message: `<strong>Invalid Rows Found:</strong> (These are unprocessable)<br /> Row numbers: ${this.modal.content.invalidEntriesRowNumber.join(', ')}`,
        timeout: 15000,
      });
    }
  }

  private handleInvalidZipcodesFlash() {
    if (this.modal.content.invalidZipcodes.length > 0) {
      this.flashService.add({
        type: 'warning',
        message: `<strong>Invalid Zipcodes Found:</strong> (These are unprocessable)<br /> Zipcodes: ${this.modal.content.invalidZipcodes.join(', ')}`,
        timeout: 15000,
      });
    }
  }

  private formatData(data: Data) {
    this.availabilities = data.map(({ id, source, zipcode, created_at, availability }) => {
      return {
        availability,
        id,
        created_on: moment(created_at).format('LLL'),
        source: source.charAt(0).toUpperCase() + source.slice(1),
        zipcode,
      }
    })
  }
}
