import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashService} from '../../components/flash/flash.service';
import {BsModalService} from 'ngx-bootstrap/modal';

import {PromoCode} from '../promo-code';
import DiscountTypes from '../promo-code';
import {PromoCodeService} from '../promo-code.service';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {NewPromoCodeComponent} from '../new-promo-code/new-promo-code.component';

import * as moment from 'moment';

@Component({
  selector: 'app-promo-codes',
  templateUrl: './list.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    './list.component.scss'
  ]
})
export class PromoCodeListComponent implements OnInit {
  discountTypesFilter = [];
  promoCodes: PromoCode[];
  loading = false;
  newVenueModal: BsModalRef;
  yesNoList = [];

  tableConfig = {
    columns: {
      promo_code: {
        title: 'Promo Code',
        type: 'html',
        valuePrepareFunction: (value) => {
          return '<div class="fs-block">' + value + '</div>';
        }
      },

      discount_total: {
        title: 'Discount'
      },

      discount_type: {
        title: 'Discount Type',
        filter: {
          type: 'list',
          config: {
            list: this.discountTypesFilter
          }
        },
        valuePrepareFunction: (value) => {
          if (value === 'percentage_off') {
            return 'Percentage Off';
          } else {
            return 'Dollar Amount';
          }
        }
      },

      is_active: {
        title: 'Active?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      created_on: {
        title: 'Created On',
        compareFunction: (direction, a, b) => {
          return (moment(a).isAfter(b) ? 1 : -1) * direction;
        }
      }

    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private flashService: FlashService,
    private promoCodeService: PromoCodeService
  ) {}

  ngOnInit() {
    this.yesNoList.push({value: 'Yes', title: 'Yes'});
    this.yesNoList.push({value: 'No', title: 'No'});

    this.discountTypesFilter.push(
      {
        value: 'percentage_off',
        title: 'Percentage Off'
      }
    );
    this.discountTypesFilter.push(
      {
          value: 'dollar_amount',
          title: 'Dollar Amount'
      }
    );

    this.route.data.subscribe((data) => {
      this.mutatePromoCode(data.promoCodes.data);
    });
  }

  public openNewPromoModal() {
    this.newVenueModal = this.modalService.show(NewPromoCodeComponent);

    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      if (this.newVenueModal.content.added) {
        this.newVenueModal.content.added = false;
        this.loading = true;

        this.flashService.add({
          type: 'success',
          message: '<strong>Success!</strong> New Promo Code Added.'
        });

        this.promoCodeService.list()
        .subscribe(promoCodes => {
          this.promoCodes = promoCodes.data
          this.mutatePromoCode(promoCodes.data);
          this.loading = false;
        });
      }

      hiddenSubscription.unsubscribe();
    });

    return false;
  }

  public onRowSelect(event) {
    this.router.navigate(['/promo-codes', event.data.id]);
  }

  private mutatePromoCode(promoCodes: PromoCode[]) {
    if(!this.promoCodes){
      this.promoCodes = promoCodes;
    }

    this.promoCodes.forEach(promo => {
      promo['created_on'] = moment(promo['created_at']).format('LLL');
      promo['is_active'] = promo.active ? 'Yes' : 'No';
      promo['discount_total'] = promo['discount_type'] === 'percentage_off' ?
        promo['discount'] + '%' : '$' + (Math.round(promo['discount'] * 100) / 100).toFixed(2);

    });
  }

}

