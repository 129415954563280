import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as moment from 'moment';

import { UserService } from '../user.service';
import { FlashService } from '../../components/flash/flash.service';
import { User } from '../user';
import { Hospital } from '../../hospitals/hospital';

@Component({
  selector: 'app-users-view',
  templateUrl: './b2c.component.html',
  styleUrls: [
    '../../../themes/ng2-smart-table.scss',
    './b2c.component.scss'
  ]
})
export class UsersB2CComponent implements OnInit {
  newUserModal: BsModalRef;
  users: User[];
  hospitals: Hospital[];
  hospitalMapper: any;
  hospitalFilterList = [];
  yesNoList = [];
  loading = false;

  tableConfig = {
    columns: {
      first_name: {
        title: 'First Name',
        type: 'html',
        valuePrepareFunction: (value) => {
          return '<div class="fs-block">' + value + '</div>';
        }
      },

      last_name: {
        title: 'Last Name',
        type: 'html',
        valuePrepareFunction: (value) => {
          return '<div class="fs-block">' + value + '</div>';
        }
      },

      email: {
        title: 'Email',
        type: 'html',
        valuePrepareFunction: (value) => {
          return '<div class="fs-block">' + value + '</div>';
        }
      },

      hospital_name: {
        title: 'Organization',
        filter: {
          type: 'list',
          config: {
            list: this.hospitalFilterList
          }
        }
      },

      is_confirmed: {
        title: 'Confirmed?',
        filter: {
          type: 'list',
          config: {
            list: this.yesNoList
          }
        }
      },

      created_on: {
        title: 'Created On',
        width: '200px',
        compareFunction: (direction, a, b) => {
          return (moment(a).isAfter(b) ? 1 : -1) * direction;
        }
      }
    },

    actions: {
      add: false,
      edit: false,
      delete: false
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.yesNoList.push({value: 'Yes', title: 'Yes'});
    this.yesNoList.push({value: 'No', title: 'No'});

    this.route.data.subscribe((data) => {
      this.hospitalMapper = data.hospitals.data.reduce((map, hospital) => {
        map[hospital.id] = hospital.name;

        this.hospitalFilterList.push({
          value: hospital.name,
          title: hospital.name
        });

        return map;
      }, {});

      this.hospitals = data.hospitals.data;

      this.mutateUsers(data.users.data);
    });
  }

  private mutateUsers(users: User[]) {
    if(!this.users){
      this.users = users;
    } 

    this.users.forEach(user => {
      user['hospital_name'] =  this.hospitalMapper[user.hospital_id] || 'B2C';
      user['created_on'] = moment(user['created_at']).format('LLL');
    });
  }

  public onRowSelect(event) {
    this.router.navigate(['/b2c', event.data.id]);
  }

}
