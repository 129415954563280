import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';
import {ComponentsModule} from '../components/components.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule} from 'ngx-bootstrap/tabs';
import {Ng2SmartTableModule} from 'rt-ng2-smart-table';
import { UiSwitchModule } from 'ngx-ui-switch';
import {FormsModule } from '@angular/forms';
import { RidshareAvailabilityComponent } from './rideshare-availability.component';
import { RideshareAvailabilityResolver } from './rideshare-availability-resolver.service';
import { RideshareAvailabilityService } from './rideshare-availability.service';
import { NewRideshareAvailabilityModalComponent } from './new-modal/new-modal.component';
import { RideshareAvailabilityCSVParserService } from './rideshare-availability-csv-parser.service';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ComponentsModule,
        RouterModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        Ng2SmartTableModule,
        UiSwitchModule,
        FormsModule
    ],
    declarations: [
        RidshareAvailabilityComponent,
        NewRideshareAvailabilityModalComponent,
    ],
    exports: [
        RidshareAvailabilityComponent,
        NewRideshareAvailabilityModalComponent,
    ],
    providers: [
        RideshareAvailabilityService,
        { provide: 'RideshareAvailabilityResolver', useValue: RideshareAvailabilityResolver },
        RideshareAvailabilityCSVParserService,
    ]
})
export class RideshareAvailabilityModule {}
