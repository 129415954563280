import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';

import { Ng2SmartTableModule } from 'rt-ng2-smart-table';
import { RouterModule } from '@angular/router';
import { CustomerResolver } from './customer-resolver.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from './customer.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CustomersViewComponent } from './view/view.component';
import { EditCustomerComponent } from './edit/edit.component';
import { NewCustomerComponent } from './new/new.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FederatedIdentityGroupsModule } from '../federated-identity-groups/federated-identity-groups.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CustomerTabsComponent } from './customer-tabs/customer-tabs.component';

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        Ng2SmartTableModule,
        AngularMultiSelectModule,
        FederatedIdentityGroupsModule,
        RouterModule,
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        SortableModule.forRoot(),
        CollapseModule.forRoot()
    ],
    declarations: [
        CustomersViewComponent,
        EditCustomerComponent,
        NewCustomerComponent,
        CustomerTabsComponent
    ],
    exports: [
    ],
    providers: [
        { provide: 'CustomerResolver', useValue: CustomerResolver },
        CustomerService,
    ]
})
export class CustomersModule { }
