<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">B2C/B2B2C</li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      (userRowSelect)="onRowSelect($event)"
      [source]="users">
    </ng2-smart-table>
  </div>
</div>
