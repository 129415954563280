<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Promo Codes</li>
  <li>
    <a href="#" (click)="openNewPromoModal()">
      <app-icon icon="plus" iconClass="button-icon"></app-icon> Add Promo Code
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="promoCodes"
      (userRowSelect)="onRowSelect($event)"
    >
    </ng2-smart-table>
  </div>
</div>
