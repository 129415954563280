import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { User, ROLES } from '../user';
import { Hospital } from '../../hospitals/hospital';
import { ConfirmModalConfig } from '../../components/confirm/confirm';

import { UserService } from '../user.service';
import { FlashService } from '../../components/flash/flash.service';
import { ConfirmService } from '../../components/confirm/confirm.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { LaunchDarklyService } from '../../core/launchdarkly.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { ProviderService } from '../../providers/providers.service';
import { Provider } from '../../providers/provider';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  resetPasswordForm: FormGroup;
  updateCurrentHospitalForm: FormGroup;
  
  user: User;
  hospitals: Hospital[];
  activeProviders: Provider[];
  availableRoles: any[];
  selectedRoles: string[] = [];
  currentUserRoles: string[] = [];
  currentPopulatedRoles: any[] = []

  transportationCompanyName: string;
  isLocked = false;
  isConfirmed = false;
  isLimited = false;
  isEdit = false;
  allowEdit = false;
  isFederated = false;

  resetPasswordModalRef: BsModalRef;
  changeHospitalModalRef: BsModalRef;

  defaultPassword = 'RoundTrip1!';

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private flashService: FlashService,
    private modalService: BsModalService,
    private confirmService: ConfirmService,
    private providerService: ProviderService,
    private auth: AuthService,
    private ld: LaunchDarklyService
  ) {

    this.updateCurrentHospitalForm = new FormGroup({
      hospital: new FormControl('', Validators.required)
    });

    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.providerService.dropdownActive().subscribe(({ data }) => {
      this.activeProviders = data;
      this.transportationCompanyName = this.activeProviders.find(x => x.id == this.user.transportation_company_id)?.name
    });

    this.route.data.subscribe(data => {
      this.user = data.user.data.user;
      this.isLocked = data.user.data.is_locked;
      this.isConfirmed = data.user.data.is_confirmed;
      this.isLimited = data.user.data.limited_access;
      this.getCurrentUserRoles();
      this.populateCurrentUserRoles();
      if (typeof this.user['idp_id'] !== 'undefined') {
        this.isFederated = this.user['idp_id'].indexOf('auth0') !== 0
      }
      
      if (!this.isDispatcher(this.currentUserRoles)) {
        this.updateCurrentHospitalForm.get('hospital').setValue(this.user.current_hospital?.id);
      }
      this.resetPasswordForm.get('password').setValue(this.defaultPassword);
      
      this.hospitals = data.hospitals.data.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        
        return 0;
      });
      
      if (this.isNavCenterAgent(this.currentUserRoles) && environment.adminCreation != 'EVERYONE' && this.auth.user$) {
        const authorizedUsers = environment.adminCreation.split(',');
        var authorized = authorizedUsers.some(element => {
          return element.toLowerCase() === this.user.email.toLowerCase();
        });
        this.auth.user$.subscribe(user => this.allowEdit = authorized)
      }
      else {
        this.allowEdit = true;
      }
  
      if (environment.adminCreation != 'EVERYONE' && this.auth.user$) {
        const authorizedUsers = environment.adminCreation.split(',');
        this.auth.user$.subscribe(user => {
          var authorized = authorizedUsers.some(element => {
            return element.toLowerCase() === user.email.toLowerCase(); 
          });
          if (!user || !user.email || !authorized) {
            this.availableRoles = ROLES.filter(r => r.id != 'admin');
          }
          else {
            this.availableRoles = ROLES;
          }
          this.dropdownList = this.availableRoles;
        })
      } else {
        this.availableRoles = ROLES;
        this.dropdownList = this.availableRoles;
      }

      this.dropdownSettings = {
        singleSelection: false,
        text: "Select Roles",
        labelKey: "name",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "myclass custom-class",
        autoPosition: false,
        tagToBody: false
      };
    });
  }

  showFlashMessage(type: string, message: string){
    this.flashService.add({
      type: type,
      message: message
    });
  }

  onItemSelect(selectedRole: any) {
    this.selectedRoles.push(selectedRole.id);
  }

  OnItemDeSelect(selectedRole: any) {
    this.selectedRoles = this.selectedRoles.filter((item) => item !== selectedRole.id);
  }

  onSelectAll(items: any) {
    this.selectedRoles = [];
    items.map((item) => this.selectedRoles.push(item.id))
  }

  onDeSelectAll(items: any) {
    this.selectedRoles = [];
  }

  findRoleBasedMapping() {
    return ROLES.find(role => role.id === this.user.role).name
  }

  toggleEdit(): void {
    this.isEdit = !this.isEdit;
  }

  noOrganizationSelected(): boolean {
    return ((this.isCareCoordinator(this.selectedRoles) || this.isNavCenterAgent(this.selectedRoles)) && !this.user.hospital_id)
  }
  
  noTransportationCompanySelected(): boolean {
    return this.isDispatcher(this.selectedRoles) && this.transportationCompanyName === undefined
  }

  update(): void {
    if (this.noOrganizationSelected()){
      this.showFlashMessage("danger", "<strong>Oops!</strong> Organization value can't be blank.Please select an organization.")
    } else if (this.noTransportationCompanySelected()){
      this.showFlashMessage("danger", "<strong>Oops!</strong> Transportation company name can't be blank. Please enter a transportation company.")
    }else if(this.selectedRoles.length === 0){
      this.showFlashMessage("danger", "<strong>Oops!</strong> Role can't be empty. Please select a role.")
    }
    else{
      if(!this.isDispatcher(this.selectedRoles)){
        this.user.transportation_company_id = null;
      }
      this.userService.update(this.constructUserRequest(), this.selectedRoles).subscribe(() => {
        this.showFlashMessage("success", `${this.user.first_name} ${this.user.last_name} has been updated.`)

        if (this.user.hospital_id) {
          const new_hospital = this.hospitals.find(h => h.id == this.user.hospital_id);
          this.user.current_hospital.id = new_hospital.id;
          this.user.current_hospital.name = new_hospital.name;
        }
        this.isEdit = false;
        this.getCurrentUserRoles();
      })
    }
  }

  onProviderSelect(event) {
    this.user.transportation_company_id = event.item.id
    this.transportationCompanyName = event.item.name;
  }

  constructUserRequest(): User {
    return {
      id: this.user.id,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      email: this.user.email,
      emr_user_id: this.user.emr_user_id,
      hospital_id: this.user.hospital_id,
      active: this.user.active,
      limited_access: this.user.limited_access,
      role: this.user.role,
      transportation_company_id: this.user.transportation_company_id,
      phone: this.user.phone,
      show_facility_rides: this.user.show_facility_rides,
      show_patient_rides: this.user.show_patient_rides,
      time_zone: this.user.time_zone,
      domestic_user: this.user.domestic_user
    }
  }

  isHospitalAssigned(hospital): boolean {
    if (!this.user.hospitals) {
      return false;
    }
    return <boolean>(this.user.hospitals.find(x => x.id === hospital.id) !== undefined);
  }

  isDispatcher(arraytoCheck) {
    return (this.hasRole('dispatcher', arraytoCheck) || this.hasRole('Dispatcher', arraytoCheck))
  }

  isCareCoordinator(arraytoCheck) {
    return (this.hasRole('care_coordinator', arraytoCheck) || this.hasRole('super_user', arraytoCheck) || this.hasRole('Care Coordinator', arraytoCheck) || this.hasRole('Admin CC', arraytoCheck))
  }

  isNavCenterAgent(arraytoCheck) {
    return this.hasRole('admin', arraytoCheck) || this.hasRole('Nav Center Agent', arraytoCheck);
  }

  hasRole(roleToCheck: string, arraytoCheck: string[]) {
    return arraytoCheck.filter((item) => item === roleToCheck).length > 0;
  }

  getCurrentUserRoles(){
    let userRoles =  this.userService.getCurrentUserRoles(this.user.id);
    let roles = [];
    userRoles.forEach((userRole) => {
      userRole.data.forEach(uRole => {
        ROLES.forEach(role => {
          if (uRole === role.id) {
            roles.push(role.name)
          }
        })
      })
      this.currentUserRoles = roles;
    })
  }

  populateCurrentUserRoles(){
    let userRoles =  this.userService.getCurrentUserRoles(this.user.id);
    let roles = [];
    userRoles.forEach((userRole) => {
      userRole.data.forEach(uRole => {
        ROLES.forEach(role => {
          if (uRole === role.id) {
            roles.push(role)
          }
        })
      })
      this.currentPopulatedRoles = roles;
      this.currentPopulatedRoles.map((role) => this.selectedRoles.push(role.id))
    })
  }


  toggleHospitalAssigned(hospital) {
    const data = {
      id: this.user.id,
      hospital_id: hospital.id
    };

    if (this.isHospitalAssigned(hospital)) {
      this.userService.unassignHospital(data).subscribe(h => this.updateUserHospitals(h.data));
    } else {
      const hasNetworkHospitals = this.hospitals.filter(x => x.parent_id === hospital.id);

      if (hasNetworkHospitals.length) {
        const confirmParams: ConfirmModalConfig = {
          title: 'This is a Tier 1 organization',
          message: 'Assigning the Care Coordinator to this organization will automatically assign them to all organizations in the network. Are you sure you want to do this?',
          buttons: [
            { type: 'danger', label: 'No' },
            { type: 'success', label: 'Yes' }
          ]
        };

        const confirmModal = this.confirmService.show(confirmParams);

        confirmModal.onClose.subscribe(response => {
          if (response.toLowerCase() === 'yes') {
            this.userService.assignHospital(data).subscribe(h => this.updateUserHospitals(h.data));
          }
        });
      } else {
        this.userService.assignHospital(data).subscribe(h => this.updateUserHospitals(h.data));
      }
    }
  }

  changeHospital(template: TemplateRef<any>) {
    this.changeHospitalModalRef = this.modalService.show(template);
  }

  showResetPasswordModal(template: TemplateRef<any>) {
    this.resetPasswordModalRef = this.modalService.show(template);
  }

  resetPassword() {
    const password = this.resetPasswordForm.get('password').value;
    let type = '', message = '';

    this.userService.resetPassword(this.user.id, password)
      .subscribe({
        next: (response) => {
          this.resetPasswordModalRef.hide();
          this.showFlashMessage("success", `<strong>Nice!</strong> ${this.user.first_name}'s password has been reset!`);
          this.resetPasswordForm.get('password').setValue(this.defaultPassword);
          },
        error: (errors) => {
          this.resetPasswordModalRef.hide();
        }
      });
  }

  unlockAccount() {
    this.userService.unlockAccount(this.user.id)
      .subscribe(response => {
        this.showFlashMessage("success", `${this.user.first_name}'s account has been unlocked.`)
        this.isLocked = false;
      });
  }

  private updateUserHospitals(hospitals: Hospital[]) {
    if (this.user.hospitals) {
      this.user.hospitals.length = 0;
    } else {
      this.user.hospitals = [];
    }

    this.user.hospitals.push.apply(this.user.hospitals, hospitals);

    this.showFlashMessage("success", `<strong>Success!</strong> ${this.user.first_name}'s assigned organizations have been updated`);
  }

  markCurrent(from: number, to: number) {
    this.hospitals.map(hospital => {
      if ((hospital.id === from || hospital.id === from) && !this.isHospitalAssigned(hospital)) {
        this.user.hospitals.push(hospital);
      }
    });
  }

  updateCurrentHospital() {
    const selectControl = this.updateCurrentHospitalForm.get('hospital');
    if (selectControl.value !== this.user.current_hospital.id) {
      this.userService.updateCurrentHospital(this.user.id, selectControl.value)
        .subscribe(response => {
          setTimeout(() => {
            this.markCurrent(this.user.current_hospital.id, selectControl.value);
            this.user.current_hospital = response.data;
            this.showFlashMessage("success", `<strong>Success!</strong> ${this.user.first_name}'s current organizations have been updated`);
            
            selectControl.setValue(this.user.current_hospital.id);
            this.changeHospitalModalRef.hide();
          }, 500);
        });
    }
  }
}

