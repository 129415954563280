<app-loader *ngIf="loading"></app-loader>

<ol class="breadcrumb">
  <li class="active">Venues</li>
  <li>
    <a href="#" (click)="openNewVenueModal()">
      <app-icon icon="map-pin" iconClass="button-icon"></app-icon> Add Venue
    </a>
  </li>
</ol>

<div class="row">
  <div class="col-xs-12">
    <ng2-smart-table
      [settings]="tableConfig"
      [source]="venues"
      (userRowSelect)="onRowSelect($event)"
    >
    </ng2-smart-table>
  </div>
</div>
