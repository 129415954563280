<ol class="breadcrumb">
  <li><a routerLink="/users">Users</a></li>
  <li class="active">{{findRoleBasedMapping()}}</li>
  <li class="active">{{user.first_name}} {{user.last_name}}</li>
</ol>

<h1>{{user.first_name}} {{user.last_name}}</h1>

<tabset>
  <tab>
    <ng-template tabHeading>
      User Details
    </ng-template>

    <div *ngIf="!isEdit">
      <table class="table table-striped">
        <tbody>
          <tr>
            <th width="200px">ID</th>
            <td>{{user.id}}</td>
          </tr>

          <tr>
            <th>First Name</th>
            <td>{{user.first_name}}</td>
          </tr>

          <tr>
            <th>Last Name</th>
            <td>{{user.last_name}}</td>
          </tr>

          <tr>
            <th>Phone</th>
            <td>{{user.phone}}</td>
          </tr>

          <tr>
            <th>Email</th>
            <td>{{user.email}}</td>
          </tr>

          <tr *ngIf="isCareCoordinator(currentUserRoles)">
            <th>EMR User ID</th>
            <td>{{user.emr_user_id}}</td>
          </tr>

          <tr>
            <th valign="top">Time Zone</th>
            <td valign="top">{{user.time_zone}}</td>
          </tr>

          <tr *ngIf="isDispatcher(currentUserRoles)">
            <th valign="top">Transportation Company</th>
            <td valign="top"><a routerLink="/providers/{{user.transportation_company_id}}">{{transportationCompanyName}}</a></td>
          </tr>

          <tr *ngIf="isCareCoordinator(currentUserRoles) || isNavCenterAgent(currentUserRoles)">
            <th valign="top">Current Organization</th>
            <td valign="top"><a routerLink="/hospitals/{{user.current_hospital.id}}">{{user.current_hospital.name}}</a></td>
          </tr>

          <tr *ngIf="isCareCoordinator(currentUserRoles)">
            <th>Show Facility Rides?</th>
            <td>
              <ui-switch disabled="true" id="show_facility_rides" name="show_facility_rides" [(ngModel)]="user.show_facility_rides"></ui-switch>
            </td>
          </tr>

          <tr *ngIf="isCareCoordinator(currentUserRoles)">
            <th>Show Patient Rides?</th>
            <td>
              <ui-switch disabled="true" id="show_patient_rides" name="show_patient_rides" [(ngModel)]="user.show_patient_rides"></ui-switch>
            </td>
          </tr>

          <tr>
            <th>Active</th>
            <td>
              <ui-switch disabled="true" id="active" name="active" [(ngModel)]="user.active"></ui-switch>
            </td>
          </tr>

          <tr>
            <th>Role</th>
              <td><span *ngFor="let role of currentUserRoles; let $last=last">{{ role }}<span *ngIf="!$last">, </span></span></td>
          </tr>

          <tr *ngIf="isCareCoordinator(currentUserRoles)">
            <th>Limited Access?</th>
            <td>
              <ui-switch disabled="true" id="limited-access" name="limited-access" [(ngModel)]="user.limited_access"></ui-switch>
            </td>
          </tr>

          <tr *ngIf="isNavCenterAgent(currentUserRoles)">
            <th>Domestic User?</th>
            <td>
              <ui-switch disabled="true" id="domestic-user" name="domestic-user" [(ngModel)]="user.domestic_user"></ui-switch>
            </td>
          </tr>

          <tr>
            <th>Created On</th>
            <td>{{user.created_at | date}}</td>
          </tr>

          <tr>
            <th>Updated On</th>
            <td>{{user.updated_at | date}}</td>
          </tr>
        </tbody>
      </table>
      <div class="text-left" *ngIf="allowEdit">
        <button class="btn btn-primary" (click)="toggleEdit()">Edit</button>
        <button  class="btn btn-danger" (click)="showResetPasswordModal(resetPasswordModal)">
          <app-icon icon="repeat" iconClass="button-icon"></app-icon>
          Reset {{user.first_name}}'s Password
        </button>      
        <button class="btn btn-primary" (click)="unlockAccount()" *ngIf="isLocked">
          <app-icon icon="unlock" iconClass="button-icon"></app-icon>
          Unlock {{user.first_name}}'s Account
        </button>
      </div>      
    </div>
    <div *ngIf="isEdit">
      <form class="form-horizontal col col-sm-6" >
        <table class="table table-striped">
          <tbody>
            <tr>
              <th width="200px">ID</th>
              <td>{{user.id}}</td>
            </tr>

            <tr>
              <th>First Name</th>
              <td *ngIf="!isFederated">
                <input type="text"
                      class="form-control"
                      name="first_name"
                      [(ngModel)]="user.first_name"
                      placeholder="First Name">
              </td>
              <td *ngIf="isFederated">
                {{user.first_name}}
              </td>
            </tr>

            <tr>
              <th>Last Name</th>
              <td *ngIf="!isFederated">
                <input type="text"
                      class="form-control"
                      name="last_name"
                      [(ngModel)]="user.last_name"
                      placeholder="Last Name">
              </td>
              <td *ngIf="isFederated">
                {{user.last_name}}
              </td>
            </tr>

            <tr>
              <th>Phone</th>
              <td><input type="text"
                class="form-control"
                id="phone"
                name="phone"
                placeholder="Phone"
                pattern="[1-9]{1}[0-9]{9}"
                [(ngModel)]="user.phone">
                <div class="form-error" *ngIf="user.phone && (user.phone.length < 10 || user.phone.length > 12)">
                  <p>Phone number should be 10 digits.</p>
                </div>
              </td>
            </tr>

            <tr>
              <th>Email</th>
              <td *ngIf="!isFederated">
                <input type="email" class="form-control" id="email" name="email" placeholder="Email" [(ngModel)]="user.email">
              </td>
              <td *ngIf="isFederated">{{user.email}}</td>
            </tr>
            
            <tr>
              <th valign="top">Time Zone</th>
              <td valign="top">
                <timezone-dropdown id="timezone" name="timezone"
                [(ngModel)]="user.time_zone">
                </timezone-dropdown>
              </td>
            </tr>
          
            <tr>
              <th>Active</th>
              <td>
                <ui-switch id="active" name="active" [(ngModel)]="user.active">
                </ui-switch>
              </td>
            </tr>
          
            <tr>
              <th>Roles</th>
              <td>
                <div class="form-group">
                  <div class="col-sm-9">
                    <angular2-multiselect [data]="dropdownList" [(ngModel)]="currentPopulatedRoles" name="roles"
                      [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
                      (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)">
                    </angular2-multiselect>
                  </div>
                </div>
              </td>
            </tr>

            <tr *ngIf="isCareCoordinator(selectedRoles)">
              <th>EMR User ID</th>
              <td>
                <input
                  type="text"
                  class="form-control"
                  id="emr_user_id"
                  name="emr_user_id"
                  placeholder="EMR User ID"
                  [(ngModel)]="user.emr_user_id">
              </td>
            </tr>
            <tr *ngIf="isDispatcher(selectedRoles)">
              <th valign="top">Transportation Company</th>
              <td valign="top">
                <input
                name="transportation_company_id"
                type="text"
                class="form-control"
                [(ngModel)]="transportationCompanyName"
                [typeahead]="activeProviders"
                typeaheadOptionField="name"
                (typeaheadOnSelect)="onProviderSelect($event)"
                placeholder="Search by transportation company name"
                #selectProviders="ngModel" />
              </td>
            </tr>

            <tr *ngIf="isCareCoordinator(selectedRoles) || isNavCenterAgent(selectedRoles)">
              <th valign="top">Current Organization</th>
              <td valign="top">
                <select class="form-control" id="hospital_id" name="hospital_id" [(ngModel)]="user.hospital_id">
                  <option *ngFor="let hospital of hospitals" [value]="hospital.id">
                    {{hospital.name}}
                  </option>
                </select>
              </td>
            </tr>

            <tr *ngIf="isCareCoordinator(selectedRoles)">
              <th>Show Facility Rides?</th>
              <td>
                <ui-switch id="show_facility_rides" name="show_facility_rides" [(ngModel)]="user.show_facility_rides">
                </ui-switch>
              </td>
            </tr>

            <tr *ngIf="isCareCoordinator(selectedRoles)">
              <th>Show Patient Rides?</th>
              <td>
                <ui-switch id="show_patient_rides" name="show_patient_rides" [(ngModel)]="user.show_patient_rides">
                </ui-switch>
              </td>
            </tr>



            <tr *ngIf="isNavCenterAgent(selectedRoles)">
              <th valign="top">Domestic User?</th>
              <td>
                <ui-switch id="domestic_user" name="domestic_user" [(ngModel)]="user.domestic_user">
                </ui-switch>
              </td>
            </tr>

            <tr *ngIf="isCareCoordinator(selectedRoles)">
              <th>Limited Access?</th>
              <td>
                <ui-switch id="limited-access" name="limited-access" [(ngModel)]="user.limited_access">
                </ui-switch>
              </td>
            </tr>

            <tr>
              <th>Created On</th>
              <td>{{user.created_at | date}}</td>
            </tr>

            <tr>
              <th>Updated On</th>
              <td>{{user.updated_at | date}}</td>
            </tr>
          </tbody>
        </table>
        
        <div class="action-wrap text-left">
          <button type="button" class="btn btn-default" (click)="toggleEdit()">Close</button>
          <button type="button" class="btn btn-primary" (click)="update()">Update</button>
        </div>
      </form>
    </div>
  </tab>

  <tab *ngIf="isCareCoordinator(currentUserRoles)">
    <ng-template tabHeading>
      Assigned Organizations
    </ng-template>

    <ul class="list-group">
      <li class="list-group-item" [ngClass]="{'active': isHospitalAssigned(hospital) || hospital.id === this.user.current_hospital.id,
       'current': hospital.id === this.user.current_hospital.id }"
       (click)="hospital.id === this.user.current_hospital.id ?
        changeHospital(updateHospital) : toggleHospitalAssigned(hospital)"
        *ngFor="let hospital of hospitals">
        {{hospital.name}} <i *ngIf="hospital.id === this.user.current_hospital.id">(Current)</i>
        <span class="badge">
          <i class="fa fa-check" *ngIf="hospital.id !== this.user.current_hospital.id"></i>
          <i class="fa fa-pencil" *ngIf="hospital.id === this.user.current_hospital.id"></i>
        </span>
      </li>
    </ul>
  </tab>
</tabset>

<ng-template #updateTimezone>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Update Time Zone
    </h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="changeTimezoneModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body row">
    <form novalidate [formGroup]="updateTimezoneForm">
      <div class="form-group col-sm-12">
        <label for="timezone" class="control-label">Time Zone</label>
        <timezone-dropdown formControlName="timezone" id="timezone"
                           name="timezone">
        </timezone-dropdown>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="changeTimezoneModalRef.hide()">Close</button>
    <button type="button" class="btn btn-danger" (click)="updateUserTimezone()">Update</button>
  </div>
</ng-template>

<ng-template #updateHospital>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <app-icon icon="map-pin" iconClass="button-icon"></app-icon> Update Current Organization
    </h4>

    <button type="button" class="close pull-right" aria-label="Close" (click)="changeHospitalModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body row">
    <form novalidate [formGroup]="updateCurrentHospitalForm">
      <div class="form-group col-sm-12">
        <label for="hospital_id" class="control-label">Current Organization</label>
        <select formControlName="hospital" class="form-control" id="hospital_id" name="hospital_id">
          <option *ngFor="let hospital of hospitals" [value]="hospital.id">
            {{hospital.name}}
          </option>
        </select>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="changeHospitalModalRef.hide()">Close</button>
    <button type="button" class="btn btn-danger" (click)="updateCurrentHospital()">Update</button>
  </div>
</ng-template>

<ng-template #resetPasswordModal>
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <app-icon icon="repeat" iconClass="button-icon"></app-icon> Reset {{user.first_name}}'s Password
      </h4>

      <button type="button" class="close pull-right" aria-label="Close" (click)="resetPasswordModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body row">
      <form novalidate [formGroup]="resetPasswordForm">
        <div class="form-group col-sm-12">
          <label for="password" class="control-label">Password</label>
          <input type="text" class="form-control" formControlName="password" id="password" name="password" />
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="resetPasswordModalRef.hide()">Close</button>
      <button type="button" class="btn btn-primary" (click)="resetPassword()">Save</button>
    </div>
  </ng-template>
