import {
  Component,
  OnInit,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Hospital, organizationTypeOptions, measurementUnitOptions } from '../hospital';
import { HospitalService } from '../hospital.service';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import * as _ from 'lodash';
import { HospitalCommonComponent } from '../hospital-common';
import { AddVehicleRatesModalComponent } from '../vehicle-rates/modal/modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Provider } from '../../providers/provider';
import { ProviderService } from '../../providers/providers.service';
import { LaunchDarklyService } from '../../core/launchdarkly.service';
import { Customer } from '../../customers/customer';
import { CustomerService } from '../../customers/customer.service';


@Component({
  selector: 'app-new-modal',
  templateUrl: './new-modal.component.html',
  styleUrls: ['./new-modal.component.scss']
})
export class NewHospitalModalComponent extends HospitalCommonComponent implements OnInit {
  hospital: Hospital = this.blankHospital();
  hospitals: Hospital[];
  parentHospitals: Hospital[];
  customers: Customer[];
  activeProviders: Provider[];
  previousEnabledVehiclesIds = [];
  addVehicleRatesModal: BsModalRef;
  vehicleList = [];
  added = false;
  newHospitalForm: FormGroup;
  title: string;
  formInvalid: boolean = false;
  organizationTypeOptions = organizationTypeOptions;
  measurementUnitOptions = measurementUnitOptions;
  afterCreate = {
    next: () => {
      this.added = true;
      this.modalRef.hide();
    },
    error: () => {
      this.modalRef.hide();
    }
  };
  enableCustomers: boolean = false;

  constructor(
    public modalRef: BsModalRef,
    protected hospitalService: HospitalService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private providerService: ProviderService,
    private customerService: CustomerService,
    private ld: LaunchDarklyService,
  ) {
    super(hospitalService);

    this.newHospitalForm = new FormGroup({
      address: new FormControl(''),
      advance_notice_for_ride_share_rides: new FormControl(5, Validators.required),
      autoassign_company_id: new FormControl(''),
      bidding_tolerance: new FormControl(10, Validators.required),
      bidding_tolerance_enabled: new FormControl(false),
      email_offer_text: new FormControl(''),
      enabled_vehicles: new FormControl([], Validators.required),
      eta_submission_window: new FormControl(10, Validators.required),
      name: new FormControl('', Validators.required),
      parent_id: new FormControl(''),
      customer_id: new FormControl(''),
      password_expiration_interval: new FormControl(3, Validators.required),
      password_expiration_interval_type: new FormControl('year', Validators.required),
      user_timeout_interval: new FormControl(480, [Validators.min(15), Validators.max(480)]),
      payer_types: new FormControl([], Validators.required),
      pp_submission_window: new FormControl(10, Validators.required),
      reason_types: new FormControl([], Validators.required),
      measurement_unit: new FormControl('', Validators.required),
      send_will_call_text: new FormControl(true),
      pcs_disabled: new FormControl(false),
      tax_id: new FormControl(''),
      transport_rates: new FormControl([]),
      service_phone_number: new FormControl('+18773968080', Validators.required),
      organization_type: new FormControl('', Validators.required)
    });

    ld.subscribeFlag({
      flagName: 'enable-customers',
      user: ld.currentUser,
      callback: (flagValue) => {
        this.enableCustomers = flagValue;
      }
    });
  }

  ngOnInit() {
    super.ngOnInit()
    this.providerService.dropdownActive().subscribe(({ data }) => {
      this.activeProviders = data;
    });
    this.customerService.get().subscribe(({ customers }) => {
      this.customers = customers;
    });
    this.getParentHospitals();
  }

  showPreviousModal() {
    const modalContainer = document.getElementsByClassName('show-modal')[0].parentNode.nodeName;
    document.getElementsByTagName(modalContainer)[0].classList.remove('hide-modal');
  }

  addHospital() {
    if (this.newHospitalForm.invalid) {
      this.formInvalid = true;
      return;
    }

    const enabledVehicles = this.newHospitalForm.get('enabled_vehicles').value;

    if (this.noRatesRequired(enabledVehicles)) {
      this.create();
    } else {
      const transportRates = this.newHospitalForm.get('transport_rates').value;
      const vehicleIds = this.hospitalService.getVehicleIds(enabledVehicles);
      const hasBlankRates = !transportRates.length || !!transportRates.find(rate => !rate.base || !rate.mileage);
      const vehiclesChanged = this.vehicleSelectionChanged(vehicleIds);

      if (vehiclesChanged || hasBlankRates) {
        this.addVehicleRates(vehicleIds);
      } else {
        this.create();
      }
    }
  }

  getParentHospitals() {
     this.hospitalService.get().subscribe(({ data }) => {
      this.parentHospitals = data.filter((hospital) => hospital.parent_id === null);
    });
  }

  private blankHospital(): Hospital {
    return <Hospital>{
      name: '',
      parent_id: null,
      customer_id: null
    };
  }

  private addVehicleRates(vehicleIds: string[]) {
    this.previousEnabledVehiclesIds = vehicleIds;

    this.modalService.onShow.subscribe(() => {
      const modalContainer = document.getElementsByClassName('show-modal')[0].parentNode?.nodeName;
      document.getElementsByTagName(modalContainer)[0].classList.add('hide-modal');
    });

    this.addVehicleRatesModal = this.showVehicleRatesModal();

    this.modalService.onHide.subscribe(() => {
      this.showPreviousModal();
    });

    const hiddenSubscription = this.modalService.onHidden.subscribe(reason => {
      this.showPreviousModal();

      if (this.addVehicleRatesModal.content.addedRates) {
        this.newHospitalForm
          .get('transport_rates')
          .setValue(this.addVehicleRatesModal.content.hospital.transport_rates);
      }

      hiddenSubscription.unsubscribe();
    });
  }

  private noRatesRequired(vehicles: any[]): boolean {
    const vehicleIds = vehicles.map(x => x.id);

    return !!(
      vehicleIds.length === 2 &&
      ['ride_share', 'ambulatory'].every(x => vehicleIds.includes(x))
    );
  }

  private vehicleSelectionChanged(vehicles: string[]): boolean {
    return JSON.stringify(vehicles.sort()) !== JSON.stringify(this.previousEnabledVehiclesIds.sort());
  }

  private showVehicleRatesModal(): BsModalRef {
    return this.modalService.show(AddVehicleRatesModalComponent, {
      initialState: {
        hospital: <Hospital>{
          name: this.newHospitalForm.get('name').value,
          enabled_vehicles: this.newHospitalForm.get('enabled_vehicles').value,
          transport_rates: this.newHospitalForm.get('transport_rates').value
        }
      }
    });
  }

  private create() {
    this.newHospitalForm.value.enabled_vehicles = this.selectedVehicles.map(x => x.id);
    this.hospitalService.create(this.newHospitalForm.value).subscribe(this.afterCreate);
  }
}
