import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { RouterModule } from '@angular/router';
import {ComponentsModule} from '../components/components.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {Ng2SmartTableModule} from 'rt-ng2-smart-table';
import {VenuesViewComponent} from './list/list.component';
import {VenuesService} from './venues.service';
import {VenuesResolverService} from './venues.resolver.service';
import { NewVenueComponent } from './new-venue/new-venue.component';
import { EditVenueComponent } from './edit-venue/edit-venue.component';
import {VenuesDetailsResolverService} from './venues-details.resolver.service';
import { VenueDeleteComponent } from './venue-delete/venue-delete.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ComponentsModule,
        RouterModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        Ng2SmartTableModule,
        GooglePlaceModule
    ],
    declarations: [
        VenuesViewComponent,
        NewVenueComponent,
        EditVenueComponent,
        VenueDeleteComponent
    ],
    exports: [
        VenuesViewComponent,
        NewVenueComponent,
        EditVenueComponent,
        VenueDeleteComponent
    ],
    providers: [
        VenuesService,
        { provide: 'VenuesResolverService', useValue: VenuesResolverService },
        { provide: 'VenuesDetailsResolverService', useValue: VenuesDetailsResolverService },
    ]
})
export class VenuesModule { }
